import React from 'react';

import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material';

const ProductAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: 0,
  minHeight: 'auto',
  color: theme.palette.secondary02.main,
  borderBottom: `1px solid ${theme.palette.secondary02.main}`,
  '&.Mui-expanded': {
    minHeight: 'auto',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  '& .MuiAccordionSummary-content': {
    fontWeight: 500,
    '&, &.Mui-expanded': {
      margin: theme.spacing(0, 0, 0.375)
    }
  },
  '& .MuiAccorionSummary-expandIcon': {
    padding: 0,
    marginRight: 0,
    '&.Mui-expanded': {
      color: theme.palette.primary.main
    }
  }
}));

const FilterAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&, &.Mui-expanded': {
    minHeight: 'auto',
    margin: 0,
    padding: theme.spacing(2, 5),
    transition: 'none',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 4)
    }
  },
  '& .MuiAccordionSummary-content': {
    '&, &.Mui-expanded': {
      margin: 0,
      padding: 0
    },
    '& .MuiTypography-root': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(22),
      letterSpacing: '0.005em'
    }
  },
  '& .MuiAccordionSummary-expandIcon': {
    color: theme.palette.primary.main,
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const DefaultAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: '0.0075em',
  padding: theme.spacing(3, 1),
  color: theme.palette.primary.main,
  '&, &.Mui-expanded': {
    minHeight: 'auto',
    margin: 0,
    transition: 'none'
  },
  '& .MuiAccordionSummary-content': {
    '&, &.Mui-expanded': {
      margin: 0,
      padding: 0
    }
  },
  '& .MuiAccordionSummary-expandIcon': {
    color: theme.palette.primary.main,
    paddingTop: 0,
    paddingBottom: 0,
    '& svg': {
      height: 28,
      width: 28
    }
  }
}));

export interface Props extends AccordionSummaryProps {
  type?: 'product' | 'filter' | 'default';
}

const AccordionSummary = (props: Props) => {
  const { children, type = 'default', ...other } = props;

  switch (type) {
    case 'product':
      return (
        <ProductAccordionSummary {...other}>{children}</ProductAccordionSummary>
      );
    case 'filter':
      return (
        <FilterAccordionSummary {...other}>{children}</FilterAccordionSummary>
      );
    default:
      return (
        <DefaultAccordionSummary {...other}>{children}</DefaultAccordionSummary>
      );
  }
};

export default AccordionSummary;

import theme from '../baseTheme';

export default {
  root: {
    minWidth: 'auto',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(1.5),
    '&.MuiListItemAvatar-alignItemsFlexStart': {
      marginTop: theme.spacing(0.5)
    }
  }
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Input, styled } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import Button from './Button';

const Text = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(24),
  letterSpacing: '0.0075em',
  fontWeight: 400,
  color: theme.palette.primary.main,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const PaginationButton = styled(Button)(({ theme }) => ({
  height: 32,
  width: 32,
  minWidth: 'auto',
  padding: 0,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.secondary03.main,
    color: theme.palette.common.white
  }
}));

const justify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};
export interface Props {
  count: number;
  current?: number;
  className?: string;
  ofText: string;
  onChange?: (page: number) => void;
  onPrev?: (page: number) => void;
  onNext?: (page: number) => void;
  align?: keyof typeof justify;
  dataTestIdCurrentPage?: string;
  dataTestIdTotalNumberOfPages?: string;
}

function component(props: Props) {
  const {
    align,
    count,
    current = 1,
    ofText = 'of',
    onChange,
    onPrev,
    onNext,
    dataTestIdCurrentPage,
    dataTestIdTotalNumberOfPages,
    ...other
  } = props;

  const inputRef = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setValidValue(current);
  }, [current]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      let length = 1;

      if (inputValue !== null) length = inputValue.length || 1;
      else length = page.toString().length;

      inputRef.current.setAttribute('size', length.toString());
    }
  }, [inputValue, page]);

  return (
    <Box
      {...other}
      display="flex"
      alignItems="center"
      justifyContent={align ? justify[align] : undefined}
    >
      <PaginationButton
        disabled={count === 1 || page === 1}
        onClick={handlePrev}
        sx={{ mr: 2 }}
      >
        <ChevronLeft />
      </PaginationButton>
      <Input
        inputRef={inputRef}
        type="text"
        value={getValue()}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        disabled={count === 1}
        inputProps={{ size: '1' }}
        data-testid={dataTestIdCurrentPage}
        sx={{
          height: 32,
          p: 0,
          borderRadius: 0,
          '& input': {
            height: '100%',
            py: 0,
            px: '8.5px',
            textAlign: 'center',
            fontWeight: 500,
            color: 'primary.main',
            '&[disabled]': {
              color: 'primary03.main'
            }
          }
        }}
      />
      <Text px={1} component="span">
        {ofText}
      </Text>
      <Text component="span" data-testid={dataTestIdTotalNumberOfPages}>
        {count}
      </Text>
      <PaginationButton
        disabled={count === 1 || page === count}
        onClick={handleNext}
        sx={{ ml: 2 }}
      >
        <ChevronRight />
      </PaginationButton>
    </Box>
  );

  function getValue(): string {
    if (inputValue === null) return page.toString();
    else if (isNaN(parseInt(inputValue))) return '';
    return inputValue;
  }

  function setValidValue(value: number): number {
    if (isNaN(value)) {
      setPage(1);

      return 1;
    }

    value = value < 1 ? 1 : value;
    value = value > count ? count : value;

    setPage(value);

    return value;
  }

  function handleBlur() {
    setInputValue(null);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const tempInputValue = parseInt(event.currentTarget.value, 10);
    setInputValue(isNaN(tempInputValue) ? '' : tempInputValue.toString());
  }

  function handlePrev() {
    const validPage = setValidValue(page - 1);
    onPrev && onPrev(validPage);
  }

  function handleNext() {
    const validPage = setValidValue(page + 1);
    onNext && onNext(validPage);
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      setInputValue(null);
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.blur();

      if (event.currentTarget.value !== '') {
        const validPage = setValidValue(
          parseInt(event.currentTarget.value, 10)
        );
        onChange && onChange(validPage);
      }
    }
  }
}

export default component;

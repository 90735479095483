import theme from '../baseTheme';

export default {
  root: {
    padding: theme.spacing(2, 3),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
};

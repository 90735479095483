import { ComponentsProps } from '@mui/material/styles';

import MuiAccordion from './MuiAccordion';
import MuiBadge from './MuiBadge';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiInput from './MuiInput';
import MuiLink from './MuiLink';
import MuiList from './MuiList';
import MuiListItemText from './MuiListItemText';
import MuiStepLabel from './MuiStepLabel';
import MuiStepper from './MuiStepper';
import MuiTab from './MuiTab';
import MuiTextField from './MuiTextField';

const props: ComponentsProps = {
  MuiAccordion,
  MuiBadge,
  MuiButton,
  MuiCard,
  MuiInput,
  MuiLink,
  MuiList,
  MuiListItemText,
  MuiStepLabel,
  MuiStepper,
  MuiTab,
  MuiTextField
};

export default props;

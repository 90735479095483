import palette from '../palette';
import theme from '../baseTheme';

export default {
  root: {
    color: palette.secondary02.main,
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(1)
  },
  asterisk: {
    color: palette.error.main
  },
  outlined: {
    '&.MuiInputLabel-marginDense': {
      transform: 'translate(8px, 13px)'
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, -24px)'
    }
  }
};

import React from 'react';

import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  styled,
  SxProps,
  Theme
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import { Modify } from '../utils';

const invalidChars = ['-', '+', 'e'];

const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  borderRadius: 0,
  padding: 0,
  flex: 1,
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    backgroundColor: 'transparent'
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary03.main, 0.1),
    '@media (hover: none)': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiButton-startIcon': {
    margin: '0 1px 0 0'
  }
}));

export type Props = Modify<
  InputBaseProps,
  {
    allowzero?: boolean;
    downbuttonprops?: ButtonProps;
    incDecValue?: number;
    max?: number;
    min?: number;
    onBlur?: (newValue: number) => void;
    onClick?: (newValue: number) => void;
    size?: 'large' | 'medium' | 'small' | 'extra-small';
    sx?: SxProps<Theme>;
    sync?: boolean;
    upbuttonprops?: ButtonProps;
    value?: number;
  }
>;

function NumberInput(props: Props) {
  /**
   * Props
   */
  const {
    onClick,
    onBlur,
    disabled,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    size,
    value,
    incDecValue,
    sx,
    sync,
    ...other
  } = props;
  const defaultNumber = props?.allowzero ? 0 : 1;
  const initialNumber = value || incDecValue || defaultNumber;

  /**
   * Refs
   */
  const inputEl = React.useRef<HTMLInputElement>();

  /**
   * States
   */
  const [number, setNumber] = React.useState<number>(initialNumber);
  const [lastValue, setLastValue] = React.useState<number>(initialNumber);
  const [focusValue, setFocusValue] = React.useState<string>();
  const [changed, setChanged] = React.useState<boolean>(false);

  /**
   * Effects
   */
  React.useEffect(syncParentValueEffect, [sync, value]);
  React.useEffect(preventInvalidInputKeyEffect, []);

  // Can't useEffect because that will cause input caching issue and infinite loops
  if (value && value !== lastValue) {
    setLastValue(value);
    setNumber(value);
  }

  /**
   * Render
   */
  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="common.white"
        sx={[
          {
            boxShadow: (theme) =>
              `inset 1px 1px 0 0 ${theme.palette.secondary03.main}, inset 0 -1px 0 0 ${theme.palette.secondary03.main}`,
            '&.Mui-disabled': {
              boxShadow: '0 none'
            }
          },
          {
            '.MuiSvgIcon-root': {
              height: '1.5rem',
              width: '1.5rem',
              lineHeight: 1
            }
          },
          size === 'extra-small' && {
            '.MuiSvgIcon-root': {
              height: '1rem',
              width: '1rem'
            },
            width: 25
          },
          size === 'small' && {
            '.MuiSvgIcon-root': {
              height: '1.25rem',
              width: '1.25rem'
            },
            width: 40
          },
          size === 'medium' && {
            width: 56
          },
          size === 'large' && {
            width: 60
          }
        ]}
      >
        <ButtonStyled
          disableElevation
          variant="contained"
          disabled={disabled}
          startIcon={<Remove />}
          onClick={() => handleClick(false)}
          {...props.downbuttonprops}
        />
      </Box>
      <InputBase
        inputRef={inputEl}
        value={focusValue ?? number}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        sx={[
          ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
          {
            color: 'primary.main',
            bgcolor: 'common.white',
            boxShadow: (theme) =>
              `inset 0 0 0 1px ${theme.palette.secondary03.main}`,
            borderRight: 0,
            '& input': {
              padding: 0,
              textAlign: 'center'
            }
          },
          size === 'extra-small' && {
            height: 30,
            width: 32,
            lineHeight: '16px',
            fontSize: '10px'
          },
          size === 'small' && {
            height: 37,
            width: 45,
            lineHeight: '16px',
            fontSize: '13px'
          },
          size === 'medium' && {
            height: 55,
            width: 85,
            lineHeight: '24px',
            fontSize: '20px'
          },
          size === 'large' && {
            height: 60,
            width: 140,
            lineHeight: '24px',
            fontSize: '20px'
          }
        ]}
        {...other}
      />
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="common.white"
        sx={[
          {
            boxShadow: (theme) =>
              `inset 0 1px ${theme.palette.secondary03.main}, inset -1px -1px ${theme.palette.secondary03.main}`,
            '&.Mui-disabled': {
              boxShadow: '0 none'
            }
          },
          {
            '.MuiSvgIcon-root': {
              height: '1.5rem',
              width: '1.5rem',
              lineHeight: 1
            }
          },
          size === 'extra-small' && {
            '.MuiSvgIcon-root': {
              height: '1rem',
              width: '1rem'
            },
            width: 25
          },
          size === 'small' && {
            '.MuiSvgIcon-root': {
              height: '1.25rem',
              width: '1.25rem'
            },
            width: 40
          },
          size === 'medium' && {
            width: 56
          },
          size === 'large' && {
            width: 60
          }
        ]}
      >
        <ButtonStyled
          disableElevation
          variant="contained"
          disabled={disabled}
          startIcon={<Add />}
          onClick={() => handleClick(true)}
          {...props.upbuttonprops}
        />
      </Box>
    </Box>
  );
  /**
   * Handles
   */
  function handleClick(increment: boolean) {
    inputEl?.current?.blur && inputEl.current.blur();
    const sign = increment ? 1 : -1;
    const toChangeTo = number + sign * (incDecValue || 1);
    onClick && onClick(toChangeTo);
    setValidValue(toChangeTo);
  }
  function handleFocus() {
    setFocusValue(number.toString());
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const filteredValue = value.replace(/[^\d]/g, '');
    setChanged(true);
    if (props.max === undefined) {
      setFocusValue(filteredValue);
      return;
    }
    const maxLength = max.toString().length;
    if (filteredValue.length <= maxLength) {
      setFocusValue(filteredValue);
    }
  }
  function handleBlur() {
    let newValue = parseInt(focusValue ?? '', 10) || lastValue || defaultNumber;
    if (changed && focusValue === '') {
      newValue = defaultNumber;
    }

    onBlur && onBlur(newValue);
    setValidValue(value || number);
    setNumber(newValue);
    setChanged(false);
    setFocusValue(undefined);
  }

  /**
   * Effect defs
   */
  function syncParentValueEffect() {
    if (sync && number !== initialNumber) {
      setLastValue(number);
      setNumber(initialNumber);
    }
  }
  function preventInvalidInputKeyEffect() {
    if (inputEl.current) {
      inputEl.current.addEventListener('keydown', preventInvalidKeys);
    }
    return () => {
      if (inputEl.current) {
        inputEl.current.removeEventListener('keydown', preventInvalidKeys);
      }
    };
  }

  /**
   * Special Functions
   */
  function setValidValue(value: number) {
    if (isNaN(value)) {
      setNumber(initialNumber);
      return;
    }
    value = value < min ? min : value;
    value = value > max ? max : value;
    setNumber(value);
  }
  function preventInvalidKeys(e: KeyboardEvent) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
}

export default NumberInput;

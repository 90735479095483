import React, {
  FocusEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Box,
  ClickAwayListener,
  InputBaseProps,
  Popper,
  Grow,
  styled,
  useTheme,
  alpha
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DayPicker, DayPickerProps } from 'react-day-picker';
import 'react-day-picker/style.css';

import DayPickerDay from './DayPickerDay';
import { ControlledInputOptions, useDateInput } from '../utils/useDateInput';

const DayPickerWrapper = styled(Box)(({ theme }) => ({
  '& .rdp-head_cell': {
    fontWeight: 400,
    color: theme.palette.secondary02.main
  },
  '& .rdp-caption_label': {
    fontSize: theme.spacing(2),
    paddingLeft: theme.spacing(1.25),
    fontWeight: 500
  },
  '& .MuiSvgIcon-root': {
    color: 'primary.main',
    fontSize: theme.spacing(3)
  },
  '& .rdp-day': {
    position: 'relative',
    zIndex: 0,
    height: theme.spacing(5),
    fontSize: theme.spacing(1.75),

    '&:focus': {
      border: 0
    },

    '&:not(.rdp-day_disabled) .backdrop:hover': {
      borderRadius: '100%',
      border: `2px solid ${theme.palette.primary02.main}`
    },

    '& .backdrop': {
      zIndex: 5,
      position: 'absolute',
      height: theme.spacing(3),
      width: theme.spacing(3),
      boxSizing: 'content-box',
      lineHeight: 10
    },
    '&.rdp-day_selected': {
      backgroundColor: theme.palette.lighterBlue.main,

      '& .backdrop': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary02.main,
        border: `2px solid ${theme.palette.primary02.main}`,
        borderRadius: '100%',
        lineHeight: '24px',
        fontWeight: 600
      }
    },
    '&.rdp-day_range_begin': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingRight: theme.spacing(0.5),
      width: theme.spacing(4.625),
      right: theme.spacing(-0.5),

      '&:hover': {
        backgroundColor: theme.palette.lighterBlue.main
      }
    },
    '&.rdp-day_range_end': {
      paddingLeft: theme.spacing(0.5),
      width: theme.spacing(4.625),

      '&:focus': {
        border: 0
      },

      '&:hover': {
        backgroundColor: theme.palette.lighterBlue.main
      }
    },
    '&.rdp-day_range_middle': {
      backgroundColor: `${theme.palette.lighterBlue.main} !important`
    },
    '&.rdp-day_outside': {
      borderRadius: 0,
      backgroundColor: theme.palette.lightestGray.main,
      color: theme.palette.secondary03.main,

      '&.rdp-day_range_middle': {
        backgroundColor: theme.palette.lighterBlue.main,
        color: theme.palette.text.primary
      }
    },
    '&.rdp-button:hover': {
      backgroundColor: theme.palette.primary.contrastText
    }
  }
}));

const DayPickerPopper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.secondary04.main}`,
  borderRadius: '4px',
  boxShadow: `0 5px 20px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  marginTop: theme.spacing(2)
}));

export type Props = DayPickerProps & {
  id?: string;
  input?: (props: InputBaseProps) => ReactNode;
  inputOptions?: ControlledInputOptions;
};

// https://github.com/gpbl/react-day-picker/tree/master/packages/react-day-picker
// Version 8
function MuiDayPickerInput(props: Props) {
  /**
   * Custom hooks
   */
  const theme = useTheme();
  const input = useDateInput({
    defaultSelected: new Date(),
    fromYear: new Date().getFullYear(),
    toYear: new Date().getFullYear() + 1,
    format: 'P',
    onSelect: props.onDayClick as (day: Date) => void,
    ...props.inputOptions
  });

  /**
   * Refs
   */
  const inputEl = useRef<HTMLInputElement | null>(null);

  /**
   * State
   */
  const [open, setOpen] = useState(false);

  /**
   * Effects
   */
  useEffect(setCssVars, [theme]);

  /**
   * Callbacks
   */
  const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    input.inputProps.onFocus?.(event);
    setOpen(true);
  };

  return props.input ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        {props.input({
          ...input.inputProps,
          ref: inputEl,
          onFocus: handleFocus
        })}
        <Popper
          id={open ? props.id : undefined}
          open={open}
          anchorEl={inputEl.current}
          placement="bottom"
          transition
          modifiers={[
            {
              name: 'flip',
              enabled: false
            }
          ]}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <DayPickerPopper>
                <DayPickerWrapper>
                  <DayPicker
                    {...input.dayPickerProps}
                    components={{
                      DayContent: DayPickerDay,
                      IconLeft: () => <ChevronLeftIcon />,
                      IconRight: () => <ChevronRightIcon />,
                      ...props.components
                    }}
                    disabled={props.disabled}
                    showOutsideDays
                  />
                </DayPickerWrapper>
              </DayPickerPopper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  ) : (
    <DayPickerWrapper>
      <DayPicker
        {...props}
        showOutsideDays
        components={{
          DayContent: DayPickerDay,
          IconLeft: () => <ChevronLeftIcon />,
          IconRight: () => <ChevronRightIcon />,
          ...props.components
        }}
      />
    </DayPickerWrapper>
  );

  function setCssVars() {
    document.documentElement.style.setProperty(
      '--rdp-accent-color',
      theme.palette.primary02.main
    );
  }
}

export default MuiDayPickerInput;

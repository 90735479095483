import React from 'react';

import MuiTable, { TableProps } from '@mui/material/Table';

export interface Props extends TableProps {
  lastRowDivider?: boolean;
}

function Table(props: Props) {
  const { children, lastRowDivider = true, sx, ...other } = props;

  return (
    <MuiTable
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        lastRowDivider === false && {
          '& .MuiTableBody-root .MuiTableRow-root:last-child': {
            '& th, & td': {
              border: '0 none'
            }
          }
        }
      ]}
      {...other}
    >
      {children}
    </MuiTable>
  );
}

export default Table;

import React from 'react';

import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';

import { Modify } from '../utils';

export type Props = Modify<
  IconButtonProps,
  {
    color?: 'inherit' | 'primary' | 'secondary' | 'secondaryMedium' | 'default';
  }
>;

function IconButton(props: Props) {
  const { children, color = 'primary', size = 'medium', sx, ...other } = props;

  return (
    <MuiIconButton
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        color === 'secondary' && {
          color: 'primary02.main',
          '&:hover': {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary02.main,
                theme.palette.action.hoverOpacity
              ),
            '@media (hover: none)': {
              bgcolor: 'transparent'
            }
          }
        },
        color === 'secondaryMedium' && {
          color: 'secondary03.main',
          '&:hover': {
            bgcolor: (theme) =>
              alpha(
                theme.palette.secondary03.main,
                theme.palette.action.hoverOpacity
              ),
            '@media (hover: none)': {
              bgcolor: 'transparent'
            }
          }
        }
      ]}
      color={color === 'secondaryMedium' ? 'primary' : color}
      size={size}
      {...other}
    >
      {children}
    </MuiIconButton>
  );
}

export default IconButton;

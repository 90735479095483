import { Theme, useMediaQuery } from '@mui/material';

export default function useScreenSize() {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const isPrint = useMediaQuery('print');

  return {
    isPrint,
    isSmallScreen
  };
}

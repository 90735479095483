import { TypographyOptions } from '@mui/material/styles/createTypography';

import theme from './baseTheme';

const typographyOptions: TypographyOptions = {
  fontFamily: ['Roboto', 'sans-serif'].join(', '),
  h1: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(48.83),
    lineHeight: theme.typography.pxToRem(56),
    letterSpacing: '0.0075em'
  },
  h2: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(39.06),
    lineHeight: theme.typography.pxToRem(46),
    letterSpacing: '0.0075em'
  },
  h3: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(31.25),
    lineHeight: theme.typography.pxToRem(37),
    letterSpacing: '0.0075em'
  },
  h4: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(25),
    lineHeight: theme.typography.pxToRem(30),
    letterSpacing: '0.0075em'
  },
  h5: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.0075em'
  },
  h6: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.0075em'
  },
  body1: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.005em'
  },
  button: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.005em',
    textTransform: 'none'
  },
  caption: {
    fontSize: theme.typography.pxToRem(12.8),
    lineHeight: theme.typography.pxToRem(16.2),
    letterSpacing: 0
  }
};

// const { typography } = createTheme({ typography: typographyOptions });

export default typographyOptions;

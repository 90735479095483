import { StepLabelProps } from '@mui/material/StepLabel';

import StepIcon from '../components/StepIcon';

const MuiStepLabelProps: Partial<StepLabelProps> = {
  StepIconComponent: StepIcon,
  children: ' '
};

export default MuiStepLabelProps;

import React from 'react';

import MuiAccordionDetails, {
  AccordionDetailsProps
} from '@mui/material/AccordionDetails';
import { styled } from '@mui/material';

const ProductAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1.5, 3, 2)
}));

const FilterAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 5)
}));

export interface Props extends AccordionDetailsProps {
  type?: 'product' | 'filter' | 'default';
}

const AccordionDetails = (props: Props) => {
  const { children, type = 'default', ...other } = props;

  switch (type) {
    case 'product':
      return (
        <ProductAccordionDetails {...other}>{children}</ProductAccordionDetails>
      );
    case 'filter':
      return (
        <FilterAccordionDetails {...other}>{children}</FilterAccordionDetails>
      );
    default:
      return <MuiAccordionDetails {...other}>{children}</MuiAccordionDetails>;
  }
};

export default AccordionDetails;

import { alpha } from '@mui/material/styles';

import palette from '../palette';
import theme from '../baseTheme';

export default {
  paper: {
    color: palette.primary03.main,
    borderRadius: '4px',
    border: `1px solid ${palette.secondary04.main}`,
    marginTop: theme.spacing(0.625),
    boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
    '& .MuiListItem-button:hover': {
      backgroundColor: alpha(palette.primary02.main, 0.1)
    }
  }
};

import React from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';

import { Modify } from '../utils';

export type Props = Modify<
  ButtonProps,
  {
    color?:
      | 'inherit'
      | 'primary'
      | 'primaryLight'
      | 'secondary'
      | 'gray'
      | 'lighterBlue'
      | 'success'
      | 'default';
    iconColor?:
      | 'inherit'
      | 'primary'
      | 'primaryLight'
      | 'secondary'
      | 'gray'
      | 'default';
    variant?: 'primary' | 'secondary' | 'alternative' | 'text' | 'inline';
    square?: boolean;
    css?: null;
  }
>;

const Button = React.forwardRef(function Button(
  props: Props,
  ref:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined
) {
  const {
    children,
    square = false,
    disableElevation = true,
    color = 'primary',
    iconColor = 'inherit',
    sx,
    variant = 'primary',
    ...other
  } = props;

  const colorMap = {
    primary: 'primary',
    primaryLight: 'primary02',
    secondary: 'secondary',
    gray: 'primary03',
    lighterBlue: 'lighterBlue',
    success: 'success',
    default: 'primary'
  };

  const variantToBaseMap: { [key: string]: ButtonProps['variant'] } = {
    primary: 'contained',
    secondary: 'outlined',
    alternative: 'contained',
    text: 'text',
    inline: 'text'
  };

  const customVariantMap = (color: Props['color'] = 'primary') => ({
    primary: color !== 'inherit' && {
      bgcolor: `${colorMap[color]}.main`,
      color: `${colorMap[color]}.contrastText`,
      '&:hover': {
        bgcolor: `${colorMap[color]}.main`,
        color: `${colorMap[color]}.contrastText`
      },
      '& svg': {
        color: `${colorMap[color]}.contrastText`
      }
    },
    alternative: {
      color: 'primary02.contrastText',
      bgcolor: 'primary02.main',
      '&:hover': {
        bgcolor: 'primary02.dark',
        '@media (hover: none)': {
          bgcolor: 'primary02.main'
        }
      }
    },
    text: color !== 'inherit' && {
      color: `${colorMap[color]}.main`,
      '&:hover': {
        color: `${colorMap[color]}.light`
      }
    },
    inline: {
      padding: 0,
      '& .MuiButton-startIcon': {
        mr: 0.5
      },
      ...(color !== 'inherit'
        ? {
            color: `${colorMap[color]}.main`,
            '&:hover': {
              color: `${colorMap[color]}.light`
            }
          }
        : {})
    }
  });

  return (
    <MuiButton
      ref={ref}
      disableElevation={disableElevation || variant !== 'secondary'}
      variant={variantToBaseMap[variant]}
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        square && {
          borderRadius: 0
        },
        iconColor === 'primary' && {
          '& svg': {
            color: 'primary.main'
          }
        },
        customVariantMap(color)[variant] ?? {}
      ]}
      {...other}
    >
      {children}
    </MuiButton>
  );
});

export default Button;

import { alpha } from '@mui/material/styles';

import palette from '../palette';

export default {
  root: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: alpha(palette.primary02.main, 0.1)
    }
  },
  hover: {}
};

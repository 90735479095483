export default {
  html: {
    height: '100%'
  },
  body: {
    height: '100%',
    display: 'flex',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  'input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  'input[type="number"]': {
    MozAppearance: 'textfield'
  },
  '::placeholder': {
    textOverflow: 'ellipsis'
  }
};

import React from 'react';

import Box from '@mui/material/Box';
import MuiCircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import { get } from 'lodash-es';

import { Modify } from '../utils';

export type Props = Modify<
  CircularProgressProps,
  {
    color?: any;
  }
>;

function CircularProgress(props: Props) {
  const { size = 80, thickness = 5, color = 'inherit', sx, ...other } = props;

  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <MuiCircularProgress
        variant="determinate"
        sx={{ color: 'lightGray.main' }}
        size={size}
        thickness={thickness}
        value={100}
        {...other}
      />
      <MuiCircularProgress
        variant="indeterminate"
        sx={[
          ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
          {
            position: 'absolute',
            left: 0,
            color: (theme) =>
              color === 'primary'
                ? 'primary.main'
                : color === 'secondary'
                ? 'secondary.main'
                : color === 'inherit'
                ? 'inherit'
                : get(theme.palette, color)
          }
        ]}
        size={size}
        thickness={thickness}
        {...other}
      />
    </Box>
  );
}

export default CircularProgress;

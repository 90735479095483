import React from 'react';
import clsx from 'clsx';

import MuiStepper, { StepperProps } from '@mui/material/Stepper';

export interface Props extends StepperProps {
  background?: 'default' | 'gray';
  hideLabels?: boolean;
  highlightCompleted?: boolean;
}

function Stepper(props: Props) {
  const {
    className,
    children,
    background = 'default',
    hideLabels = false,
    highlightCompleted = true,
    sx,
    ...other
  } = props;

  return (
    <MuiStepper
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        {
          bgcolor: 'transparent',
          p: 1.5
        },
        hideLabels && {
          '& .MuiStepLabel-labelContainer': {
            display: 'none'
          }
        }
      ]}
      className={clsx(className, {
        'MuiStepper-backgroundGray': background === 'gray',
        'MuiStepper-highlightCompleted': highlightCompleted === true
      })}
      {...other}
    >
      {children}
    </MuiStepper>
  );
}

export default Stepper;

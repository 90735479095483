import { alpha } from '@mui/material/styles';

import palette from '../palette';

export default {
  container: {
    backgroundBlendMode: 'multiply',
    backdropFilter: 'blur(2px)'
  },
  paper: {
    boxShadow: `0 3px 10px 0 ${alpha(palette.common.black, 0.3)}`
  }
};

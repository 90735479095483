import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import { StepIconProps } from '@mui/material/StepIcon';
import { alpha } from '@mui/material/styles';

export interface Props extends StepIconProps {}

function StepIcon(props: Props) {
  const { className, active, completed } = props;

  return (
    <Box
      className={clsx(
        className,
        active && 'MuiStepIcon-active',
        completed && 'MuiStepIcon-completed'
      )}
      sx={[
        {
          color: 'common.white',
          display: 'flex',
          alignItems: 'center',
          height: 24,
          zIndex: 2
        },
        active === true && { color: 'primary02.main' }
      ]}
    >
      <Box
        sx={{
          width: '1.5em',
          height: '1.5em',
          borderRadius: '50%',
          bgcolor: 'common.white',
          '.MuiStepper-backgroundGray &': {
            bgcolor: 'secondary04.main'
          },
          '.MuiStepIcon-completed &': {
            bgcolor: 'primary02.main'
          },
          '.MuiStepIcon-active &': {
            bgcolor: 'primary02.main',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: -3,
              bottom: -3,
              left: -3,
              right: -3,
              bgcolor: (theme) => alpha(theme.palette.primary02.main, 0.2),
              borderRadius: '50%'
            }
          }
        }}
      />
    </Box>
  );
}

export default StepIcon;

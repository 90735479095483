import { PaletteOptions, createTheme } from '@mui/material';

import theme from './baseTheme';

const {
  palette: { augmentColor }
} = theme;

const paletteOptions: PaletteOptions = {
  primary: {
    main: '#003766',
    light: '#024e8e',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#ffa500',
    contrastText: '#ffffff'
  },
  error: {
    main: '#f0553d'
  },
  warning: {
    main: '#f0cc00',
    contrastText: '#606060'
  },
  info: {
    main: '#015ce2'
  },
  success: {
    main: '#407a26'
  },
  background: {
    default: '#f7f7f7'
  },
  text: {
    primary: '#404040',
    secondary: '#606060'
  },
  primary02: augmentColor({
    color: {
      main: '#0b66ec'
    }
  }),
  primary03: augmentColor({
    color: {
      main: '#404040'
    }
  }),
  secondary02: augmentColor({
    color: {
      main: '#606060'
    }
  }),
  secondary03: augmentColor({
    color: {
      main: '#cbcbcb'
    }
  }),
  secondary04: augmentColor({
    color: {
      main: '#f2f2f2'
    }
  }),
  secondary05: augmentColor({
    color: {
      main: '#F4F9FF',
      light: '#AFAFAF'
    }
  }),
  secondary06: augmentColor({
    color: {
      main: '#FFEDCC'
    }
  }),
  secondary07: augmentColor({
    color: {
      main: '#ffdb99',
      light: '#E9ABA1'
    }
  }),
  darkGray: augmentColor({
    color: {
      main: '#525252'
    }
  }),
  darkOrange: augmentColor({
    color: {
      main: '#af5500'
    }
  }),
  darkYellow: augmentColor({
    color: {
      main: '#9d6601'
    }
  }),
  lightBlue: augmentColor({
    color: {
      main: '#44c7f4'
    }
  }),
  lightGray: augmentColor({
    color: {
      main: '#d6d7d9'
    }
  }),
  lighterBlue: augmentColor({
    color: {
      main: '#e6effc',
      contrastText: '#003766'
    }
  }),
  lighterGray: augmentColor({
    color: {
      main: '#e9e9e9'
    }
  }),
  lightestGray: augmentColor({
    color: {
      main: '#f7f7f7'
    }
  }),
  mediumGray: augmentColor({
    color: {
      main: '#939393'
    }
  }),
  orangeRed: augmentColor({
    color: {
      main: '#c82d15'
    }
  }),
  purple: augmentColor({
    color: {
      main: '#8c44ef'
    }
  }),
  warmGray: augmentColor({
    color: {
      main: '#f5f1ed'
    }
  }),
  poshRed: augmentColor({
    color: {
      main: '#F1A693'
    }
  }),
  bigDogBrand: augmentColor({
    color: {
      main: '#FF5100'
    }
  }),
  waterWorksWarning: augmentColor({
    color: {
      main: '#FAEAE8',
      contrastText: '#C82D15'
    }
  })
};

const { palette } = createTheme({ palette: paletteOptions });

export default palette;

import palette from '../palette';
import theme from '../baseTheme';

export default {
  root: {
    color: palette.secondary03.main,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.005em',
    textTransform: 'capitalize' as const,
    padding: theme.spacing(0.5),
    minWidth: 'auto',
    minHeight: 'auto',
    '& + &': {
      marginLeft: theme.spacing(4)
    },
    '@media (min-width: 600px)': {
      minWidth: 'auto'
    }
  },
  textColorInherit: {
    color: palette.secondary03.main,
    opacity: 1,
    '&.Mui-selected': {
      color: 'inherit',
      opacity: 1
    },
    '&.Mui-disabled': {
      color: palette.text.disabled,
      opacity: 1
    }
  }
};

import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

import { Modify } from '../utils';

const iconMap = {
  error: <ErrorIcon />,
  info: <InfoIcon />,
  success: <CheckCircleIcon />,
  warning: <WarningIcon />
};

export type Props = Modify<
  AlertProps,
  {
    severity?: AlertColor;
  }
>;

function Alert(props: Props) {
  const { severity = 'default', sx } = props;

  const colorMap = {
    success: 'success.main',
    info: 'info.main',
    error: 'error.main',
    warning: 'darkOrange.main'
  };

  return (
    <MuiAlert
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        {
          color: colorMap[severity] ?? 'inherit'
        },
        severity === 'warning' && {
          '& > .MuiAlert-icon': {
            color: 'darkOrange.main'
          }
        }
      ]}
      icon={iconMap[severity]}
      {...props}
    />
  );
}

export default Alert;

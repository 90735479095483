import React, { ReactNode } from 'react';

import { uniqueId } from 'lodash-es';

import MuiSelect, { SelectProps } from '@mui/material/Select';
import { FormHelperText, Input, InputLabel } from '@mui/material';

export interface Props extends SelectProps {
  helperText?: React.ReactNode;
  size?: 'small' | 'medium';
}

function Select(props: Props) {
  const {
    children,
    error,
    label,
    helperText,
    renderValue: renderValueProp,
    required,
    placeholder,
    size = 'medium',
    sx,
    ...other
  } = props;

  return (
    <React.Fragment>
      {label ? (
        <InputLabel
          id={uniqueId('select-label-')}
          error={error}
          required={required}
        >
          {label}
        </InputLabel>
      ) : null}
      <MuiSelect
        sx={[
          ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
          {
            p: 0,
            '& .MuiSelect-select': {
              display: 'inline-flex',
              alignItems: 'center'
            },
            '& .MuiSelect-icon': {
              color: 'primary03.main',
              right: (theme) => theme.spacing(3)
            },
            '& .MuiSelect-select:focus': {
              bgcolor: 'initial'
            }
          },
          size === 'small' && {
            '& .MuiSelect-select': (theme) => ({
              py: 1,
              pl: 2,
              pr: 8.25,
              height: 'auto',
              ...theme.typography.caption
            }),
            '& .MuiSelect-icon': {
              right: (theme) => theme.spacing(2)
            }
          },
          size === 'medium' && {
            '& .MuiSelect-select': {
              py: 1.5,
              pl: 2,
              pr: 10.25
            }
          }
        ]}
        input={<Input />}
        displayEmpty={placeholder !== undefined}
        renderValue={renderValue}
        error={error}
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              border: 1,
              borderColor: 'lightGray.main',
              boxShadow: 'none',
              mt: 1
            },
            '& .MuiMenu-list': {
              py: 2,
              '& .MuiMenuItem-root': {
                py: 1,
                px: 2
              }
            }
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          elevation: 0
        }}
        {...other}
      >
        {children}
      </MuiSelect>
      {helperText ? (
        <FormHelperText
          sx={{
            mt: 0.375,
            mb: 0,
            mx: 1,
            color: 'darkGray.main',
            '.Mui-error + &': {
              color: 'error.main'
            }
          }}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </React.Fragment>
  );

  function renderValue(value: unknown): ReactNode {
    if (renderValueProp !== undefined) {
      return renderValueProp(value);
    }

    return value !== '' ? `${value}` : placeholder;
  }
}

export default Select;

import palette from '../palette';
import theme from '../baseTheme';

export default {
  root: {
    '.MuiCard-root &': {
      padding: theme.spacing(0, 2.5),
      '& tbody tr th': {
        color: palette.primary.main
      }
    }
  }
};

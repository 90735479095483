import theme from '../baseTheme';

export default {
  root: {
    padding: theme.spacing(2, 3)
  },
  action: {
    marginTop: -4,
    marginRight: -8,
    marginBottom: -4
  }
};

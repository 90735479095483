import palette from '../palette';

export default {
  multiline: {
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    color: palette.primary.main
  },
  secondary: {
    color: palette.secondary02.main
  }
};

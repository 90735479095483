import { ComponentsOverrides } from '@mui/material/styles';

import MuiAvatar from './MuiAvatar';
import MuiBadge from './MuiBadge';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDialog from './MuiDialog';
import MuiDivider from './MuiDivider';
import MuiDrawer from './MuiDrawer';
import MuiFormHelperText from './MuiFormHelperText';
import MuiInput from './MuiInput';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiListItem from './MuiListItem';
import MuiListItemAvatar from './MuiListItemAvatar';
import MuiListItemText from './MuiListItemText';
import MuiMenu from './MuiMenu';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiRadio from './MuiRadio';
import MuiStepConnector from './MuiStepConnector';
import MuiStepLabel from './MuiStepLabel';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableContainer from './MuiTableContainer';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiTextField from './MuiTextField';
import MuiTypography from './MuiTypography';

const overrides: ComponentsOverrides = {
  MuiAvatar,
  MuiBadge,
  MuiBreadcrumbs,
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiDialog,
  MuiDivider,
  MuiDrawer,
  MuiFormHelperText,
  MuiInput,
  MuiInputBase,
  MuiInputLabel,
  MuiListItem,
  MuiListItemAvatar,
  MuiListItemText,
  MuiMenu,
  MuiOutlinedInput,
  MuiPaper,
  MuiRadio,
  MuiStepConnector,
  MuiStepLabel,
  MuiTab,
  MuiTableCell,
  MuiTableContainer,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiTypography
};

export default overrides;

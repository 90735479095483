import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react';

import { Box, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import { uniqueId } from 'lodash-es';

import Alert from './Alert';
import { useScreenSize } from '../utils';

type MessageOptions = {
  variant?: AlertColor;
};

type SnackbarQueueMessage = {
  id: string;
  message: string;
  timestamp: number;
  options?: MessageOptions;
};

type SnackbarContextState = {
  queue: SnackbarQueueMessage[];
  pushAlert: (message: string, options?: MessageOptions) => void;
};

const SnackbarContext = createContext<SnackbarContextState>(
  {} as SnackbarContextState
);

export function useSnackbar() {
  const { pushAlert } = useContext(SnackbarContext);

  return {
    pushAlert
  };
}

export type Props = {
  children?: ReactNode;
};

function SnackbarProvider(props: Props) {
  const [queue, setQueue] = useState([] as SnackbarQueueMessage[]);
  const { isSmallScreen } = useScreenSize();

  const pushAlert = (message: string, options?: MessageOptions) => {
    setQueue([
      ...queue,
      {
        id: uniqueId(),
        message,
        timestamp: Date.now(),
        options
      }
    ]);
  };

  useEffect(() => {
    if (queue.length) {
      const timeout = setTimeout(() => {
        setQueue(
          queue.slice(1).map((a, i) => ({
            ...a,
            // reset the timer for anything that hasn't displayed yet
            // this will ensure all messages show for at least 5 secs
            timestamp: i > 1 ? Date.now() : a.timestamp
          }))
        );
      }, 5000 - (Date.now() - queue[0].timestamp));

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [queue, setQueue]);

  // Reverse if displaying on top
  const alerts = isSmallScreen
    ? queue.slice(0, 3)
    : queue.slice(0, 3).reverse();

  return (
    <SnackbarContext.Provider
      value={{
        queue,
        pushAlert
      }}
    >
      {props.children}
      <Snackbar
        open={Boolean(queue.length)}
        anchorOrigin={{
          horizontal: 'center',
          vertical: isSmallScreen ? 'bottom' : 'top'
        }}
      >
        <div>
          {alerts.map((a) => (
            <Box p={1} key={a.id}>
              <Alert
                severity={a?.options?.variant || 'success'}
                sx={{ boxShadow: 3 }}
              >
                {a.message}
              </Alert>
            </Box>
          ))}
        </div>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;

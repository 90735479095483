import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  className?: string;
  fallback: string;
  src: string;
  alt: string;
  title?: string;
  sx?: SxProps<Theme>;
}

const Image = React.forwardRef<HTMLImageElement, Props>(function Image(
  props,
  ref
) {
  const { fallback, src, alt, sx, ...other } = props;

  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = fallback;
  };

  const securedSrc = window.location.protocol.match(/^https:/)
    ? src.replace(/^http:/, 'https:')
    : src;

  return (
    <Box
      component="img"
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        {
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto'
        }
      ]}
      ref={ref}
      src={securedSrc}
      alt={alt}
      onError={handleError}
      {...other}
    />
  );
});

export default Image;

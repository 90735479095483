import palette from '../palette';

export default {
  root: {
    '&.Mui-error + * > .MuiFormHelperText-root:first-of-type': {
      color: palette.error.main
    },
    backgroundColor: palette.primary.contrastText
  },
  input: {
    height: '1.1876em'
  }
};
